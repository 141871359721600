<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';

import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    query: Object,
    purchaseInvoices: Object,
    statusOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    status: props.query.status ?? '',
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',

    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.purchase-invoices.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['purchaseInvoices', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const typeFilters = {
    status: {
        options: { '': t('All {attributes}', { attributes: t('statuses') }), ...props.statusOptions },
    },
};

const tableSettings = [
    { label: t('Name'), orderBy: 'name', width: '15%' },
    { label: t('Invoice number') },
    { label: t('E-mail address'), cell: 'Email', width: '10%' },
    { label: t('Phone number'), cell: 'Tel', width: '10%' },
    { label: t('Date'), orderBy: 'date', width: '15%' },
    { label: t('Due date'), orderBy: 'due_date', width: '15%' },
    { label: '', width: '2%', cell: 'Arrow' },
];

const tableData = computed(() =>
    props.purchaseInvoices.data.map((purchaseInvoice) => {
        return {
            id: purchaseInvoice.id,
            colorCode: purchaseInvoice.status != 'open' ? '#C30000' : '#5C8938',
            rowData: [
                purchaseInvoice.user?.display_name,
                purchaseInvoice.number,
                purchaseInvoice.user?.email,
                purchaseInvoice.user?.phone_number,
                purchaseInvoice.date,
                purchaseInvoice.due_date,
                route('staff.purchase-invoices.show', purchaseInvoice.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('Purchase invoice')">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            :h1="$t('Purchase invoice')"
            :typeFilters="typeFilters"
            :hasSearch="true"
            @searchUpdate="debouncedGetData()"
        />

        <template v-if="typeof purchaseInvoices !== 'undefined' && purchaseInvoices.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />
            <Pagination
                v-if="typeof purchaseInvoices !== 'undefined'"
                :links="purchaseInvoices.links"
                @setPage="setPage"
                :pageData="purchaseInvoices"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('purchase invoices') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
